import * as React from "react"
import { graphql } from "gatsby"
import VideoView from "../../views/video-view"

function Video(props) {
  const { video } = props.data
  return <VideoView video={video} />
}

export default Video

export const query = graphql`
  query ($id: String!) {
    video(id: { eq: $id }) {
      name
      nameSlug: gatsbyPath(filePath: "/videos/{Video.name}")
      description
      secondary
      meta {
        id
        cover {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        src
      }
    }
  }
`
